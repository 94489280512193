import { RevertCopyMap } from 'types/Revert';

export const revertCopyMap: Partial<RevertCopyMap> = {
  WorldsUserRoles_Sender_Is_Not_World_Owner: {
    reason: 'Not world owner',
    description: 'You must be the world owner to perform this action',
  },
  AccessControl_Sender_Must_Be_Admin: {
    reason: 'Must be admin',
    description: 'You must be an administrator to perform this action',
  },
  AddressInsufficientBalance: {
    reason: 'Insufficient balance',
    description:
      'The address does not have enough balance for this transaction',
  },
  AddressLibrary_Proxy_Call_Did_Not_Return_A_Contract: {
    reason: 'Invalid proxy call',
    description: 'The proxy call did not return a valid contract address',
  },
  AdminRoleEnumerable_Caller_Does_Not_Have_Admin_Role: {
    reason: 'Not an admin',
    description:
      'You do not have administrative privileges to perform this action',
  },
  AdminRole_Caller_Does_Not_Have_Admin_Role: {
    reason: 'Not an admin',
    description:
      'You do not have administrative privileges to perform this action',
  },
  CollateralManagement_Cannot_Withdraw_To_Address_Zero: {
    reason: 'Invalid withdrawal address',
    description: 'You cannot withdraw to the zero address',
  },
  CollateralManagement_Cannot_Withdraw_To_Self: {
    reason: 'Cannot withdraw to self',
    description: 'You cannot withdraw collateral to your own address',
  },

  AccessControlBadConfirmation: {
    reason: 'Bad confirmation',
    description: 'The confirmation provided for access control is invalid',
  },
  AccessControlUnauthorizedAccount: {
    reason: 'Unauthorized account',
    description: 'The account is not authorized to perform this action',
  },
  AddressEmptyCode: {
    reason: 'Empty address code',
    description: 'The address contains no code',
  },

  DelegateForwarder_For_Internal_Use_Only: {
    reason: 'Internal use only',
    description:
      'This function is for internal use only and cannot be called externally',
  },
  DropMarketLibrary_General_Availability_Start_Time_Has_Expired: {
    reason: 'General availability expired',
    description: 'The start time for general availability has expired',
  },
  DropMarketLibrary_Mint_Permission_Required: {
    reason: 'Mint permission required',
    description: 'You must have permission to mint from this collection',
  },

  DropMarketLibrary_Time_Too_Far_In_The_Future: {
    reason: 'Time too far in future',
    description: 'The specified time is too far in the future',
  },
  ECDSAInvalidSignature: {
    reason: 'Invalid signature',
    description: 'The provided signature is invalid',
  },
  ECDSAInvalidSignatureLength: {
    reason: 'Invalid signature length',
    description: 'The length of the signature is incorrect',
  },
  ECDSAInvalidSignatureS: {
    reason: 'Invalid signature format',
    description: 'The S value in the signature is invalid',
  },
  EIP712_Invalid_Signer: {
    reason: 'Invalid signer',
    description: 'The signer of this message is invalid',
  },
  ERC1155InsufficientBalance: {
    reason: 'Insufficient balance',
    description: 'The account has an insufficient balance of the token',
  },
  ERC1155InvalidApprover: {
    reason: 'Invalid approver',
    description: 'The approver is not valid for this token',
  },
  ERC1155InvalidArrayLength: {
    reason: 'Invalid array length',
    description: 'The length of the input arrays do not match',
  },
  ERC1155InvalidOperator: {
    reason: 'Invalid operator',
    description: 'The operator is not approved to manage these tokens',
  },
  ERC1155InvalidReceiver: {
    reason: 'Invalid receiver',
    description: 'The receiver is not valid for this token transfer',
  },
  ERC1155InvalidSender: {
    reason: 'Invalid sender',
    description: 'The sender is not valid for this token transfer',
  },
  ERC1155MissingApprovalForAll: {
    reason: 'Missing approval',
    description: 'The operator is missing approval for all tokens',
  },
  ERC1167FailedCreateClone: {
    reason: 'Clone creation failed',
    description: 'Failed to create a clone instance',
  },
  ERC721IncorrectOwner: {
    reason: 'Incorrect owner',
    description: 'The provided owner address is incorrect',
  },
  ERC721InsufficientApproval: {
    reason: 'Insufficient approval',
    description: 'The account has insufficient approval for this action',
  },
  ERC721InvalidApprover: {
    reason: 'Invalid approver',
    description: 'The approver is not valid for this token',
  },
  ERC721InvalidOperator: {
    reason: 'Invalid operator',
    description: 'The operator is not approved to manage this token',
  },
  ERC721InvalidOwner: {
    reason: 'Invalid owner',
    description: 'The provided owner is not valid for this token',
  },
  ERC721InvalidReceiver: {
    reason: 'Invalid receiver',
    description: 'The receiver is not valid for this token transfer',
  },
  ERC721InvalidSender: {
    reason: 'Invalid sender',
    description: 'The sender is not valid for this token transfer',
  },
  ERC721NonexistentToken: {
    reason: 'Nonexistent token',
    description: 'The specified token does not exist',
  },
  ERC721UserRoles_User_Has_No_Roles: {
    reason: 'No roles',
    description: 'The user does not have any roles assigned',
  },
  ERC721UserRoles_User_Must_Not_Be_Zero_Address: {
    reason: 'Invalid user address',
    description: 'The user address cannot be the zero address',
  },
  ERC721UserRoles_User_Role_Already_Set: {
    reason: 'Role already assigned',
    description: 'The user already has this role assigned',
  },
  FailedInnerCall: {
    reason: 'Inner call failed',
    description: 'The internal call within the function has failed',
  },
  FETH_Cannot_Deposit_For_Lockup_With_Address_Zero: {
    reason: 'Invalid deposit address',
    description: 'You cannot deposit for lockup with the zero address',
  },
  FETH_Cannot_Deposit_To_Address_Zero: {
    reason: 'Invalid deposit address',
    description: 'You cannot deposit to the zero address',
  },
  FETH_Cannot_Deposit_To_FETH: {
    reason: 'Deposit to FETH not allowed',
    description: 'Depositing directly to the FETH contract is not allowed',
  },
  FETH_Cannot_Withdraw_To_Address_Zero: {
    reason: 'Invalid withdrawal address',
    description: 'You cannot withdraw to the zero address',
  },
  FETH_Cannot_Withdraw_To_FETH: {
    reason: 'Withdrawal to FETH not allowed',
    description: 'Withdrawing directly to the FETH contract is not allowed',
  },
  FETH_Cannot_Withdraw_To_Market: {
    reason: 'Withdrawal to market not allowed',
    description: 'You cannot withdraw directly to the market',
  },
  FETH_Escrow_Expired: {
    reason: 'Escrow expired',
    description: 'The escrow period has expired',
  },
  FETH_Escrow_Not_Found: {
    reason: 'Escrow not found',
    description: 'No escrow could be found for this transaction',
  },
  FETH_Expiration_Too_Far_In_Future: {
    reason: 'Expiration too distant',
    description: 'The set expiration date is too far in the future',
  },
  FETH_Insufficient_Allowance: {
    reason: 'Insufficient allowance',
    description: 'You have not allowed enough funds for this transaction',
  },
  FETH_Insufficient_Available_Funds: {
    reason: 'Insufficient funds available',
    description:
      'There are not enough available funds to complete this transaction',
  },
  FETH_Insufficient_Escrow: {
    reason: 'Insufficient escrow',
    description: 'There is not enough escrow to complete this transaction',
  },
  FETH_Invalid_Lockup_Duration: {
    reason: 'Invalid lockup duration',
    description: 'The specified lockup duration is invalid',
  },
  FETH_Market_Must_Be_A_Contract: {
    reason: 'Invalid market contract',
    description: 'The specified market must be a valid contract',
  },
  FETH_Must_Deposit_Non_Zero_Amount: {
    reason: 'Deposit amount must be non-zero',
    description: 'You must deposit an amount greater than zero',
  },
  FETH_Must_Lockup_Non_Zero_Amount: {
    reason: 'Lockup amount must be non-zero',
    description: 'You must lockup an amount greater than zero',
  },
  FETH_No_Funds_To_Withdraw: {
    reason: 'No funds to withdraw',
    description: 'There are no funds available to withdraw',
  },
  FETH_Only_FND_Market_Allowed: {
    reason: 'Only FND market allowed',
    description: 'Only transactions with the FND market are allowed',
  },
  FETH_Too_Much_ETH_Provided: {
    reason: 'Too much ETH provided',
    description: 'The amount of ETH provided is too high for this transaction',
  },
  FETH_Transfer_To_Address_Zero_Not_Allowed: {
    reason: 'Transfer to zero address not allowed',
    description: 'You cannot transfer to the zero address',
  },
  FETH_Transfer_To_FETH_Not_Allowed: {
    reason: 'Transfer to FETH not allowed',
    description: 'Transferring directly to the FETH contract is not allowed',
  },
  FETHNode_FETH_Address_Is_Not_A_Contract: {
    reason: 'Invalid FETH address',
    description: 'The FETH address specified is not a contract',
  },
  FETHNode_Only_FETH_Can_Transfer_ETH: {
    reason: 'Only FETH can transfer ETH',
    description: 'Only the FETH contract can transfer ETH',
  },
  FethNode_Too_Much_Value_Provided: {
    reason: 'Excessive value provided',
    description:
      'The amount provided exceeds the expected value for this operation',
  },
  FNDMiddleware_NFTs_And_TokenIds_Must_Be_The_Same_Length: {
    reason: 'Mismatched arrays',
    description: 'The length of NFTs and token IDs must match',
  },
  FoundationTreasuryNode_Address_Is_Not_A_Contract: {
    reason: 'Invalid contract address',
    description: 'The provided treasury node address is not a contract',
  },
  FoundationTreasuryNode_Caller_Not_Admin: {
    reason: 'Not an admin',
    description: 'The caller is not an administrator',
  },
  FoundationTreasuryNode_Caller_Not_Operator: {
    reason: 'Not an operator',
    description: 'The caller is not an operator',
  },
  InvalidInitialization: {
    reason: 'Invalid initialization',
    description: 'The contract initialization was invalid',
  },
  InvalidShortString: {
    reason: 'Invalid string length',
    description: 'The provided string does not meet the length requirements',
  },
  LazyMintedCollection_Mint_Count_Must_Be_Greater_Than_Zero: {
    reason: 'Invalid mint count',
    description: 'The number of items to mint must be greater than zero',
  },
  MarketUtils_Feth_Is_Not_A_Contract: {
    reason: 'Invalid FETH contract',
    description: 'The FETH address provided is not a valid contract',
  },
  MarketUtils_Royalty_Registry_Is_Not_A_Contract: {
    reason: 'Invalid royalty registry',
    description:
      'The royalty registry address provided is not a valid contract',
  },
  MinterRole_Caller_Does_Not_Have_Minter_Or_Admin_Role: {
    reason: 'Unauthorized minter',
    description: 'You must have minter or admin role to perform this action',
  },

  NFTCollection_Max_Token_Id_Has_Already_Been_Minted: {
    reason: 'Max token ID minted',
    description: 'The maximum token ID has already been minted',
  },
  NFTCollection_Token_CID_Already_Minted: {
    reason: 'Token already minted',
    description: 'A token with this CID has already been minted',
  },

  NFTCollection_Token_Creator_Payment_Address_Required: {
    reason: 'Payment address required',
    description: 'A payment address is required for the token creator',
  },
  NFTCollectionFactoryNode_Address_Is_Not_A_Contract: {
    reason: 'Invalid address',
    description: 'The provided address is not a contract',
  },

  NFTCollectionFactorySharedTemplates_Implementation_Not_A_Contract: {
    reason: 'Invalid implementation',
    description: 'The provided implementation is not a valid contract',
  },
  NFTCollectionFactorySharedTemplates_Invalid_Collection_Type: {
    reason: 'Invalid collection type',
    description: 'The provided collection type is invalid',
  },

  NotInitializing: {
    reason: 'Not initializing',
    description:
      'This function can only be called during contract initialization',
  },
  OwnableInvalidOwner: {
    reason: 'Invalid owner',
    description: 'The provided owner address is invalid',
  },
  OwnableUnauthorizedAccount: {
    reason: 'Unauthorized account',
    description: 'The account is not authorized to perform this action',
  },
  PercentSplitETH_Each_Share_Must_Be_Less_Than_100_Percent: {
    reason: 'Share exceeds limit',
    description: 'Each share must be less than 100 percent',
  },
  PercentSplitETH_ERC20_Split_Failed: {
    reason: 'ERC20 split failed',
    description: 'The ERC20 token split has failed',
  },
  PercentSplitETH_ERC20_Tokens_Must_Be_Split: {
    reason: 'ERC20 tokens must be split',
    description: 'The ERC20 tokens must be split among the recipients',
  },
  PercentSplitETH_Only_Split_Recipient_May_Call: {
    reason: 'Unauthorized caller',
    description: 'Only the split recipient may call this function',
  },
  PercentSplitETH_Too_Few_Recipients: {
    reason: 'Too few recipients',
    description: 'There are too few recipients for the split',
  },
  PercentSplitETH_Too_Many_Recipients: {
    reason: 'Too many recipients',
    description: 'There are too many recipients for the split',
  },
  PercentSplitETH_Total_Shares_Must_Equal_100_Percent: {
    reason: 'Shares must equal 100%',
    description: 'The total of all shares must equal 100%',
  },
  SelfDestructibleCollection_Minted_NFTs_Must_Be_Burned_First: {
    reason: 'Burn before self-destruct',
    description:
      'All minted NFTs must be burned before the collection can self-destruct',
  },

  StringsInsufficientHexLength: {
    reason: 'Insufficient hex length',
    description: 'The hex string does not have sufficient length',
  },
  StringTooLong: {
    reason: 'String too long',
    description: 'The provided string exceeds the maximum length',
  },

  NFTCreateAndListTimedEditionCollection_Sale_Duration_Cannot_Be_Zero: {
    reason: 'Invalid sale duration',
    description: 'The sale duration cannot be zero',
  },
  NFTDropCollection_Exceeds_Max_Token_Id: {
    reason: 'Quantity unavailable',
    description: 'There are not enough NFTs available left to mint',
  },
  NFTDropMarketCore_Collection_Already_Listed_For_Sale: {
    reason: 'Collection already listed',
    description: 'This collection is already listed for sale',
  },
  NFTDropMarketCore_Invalid_Merkle_Root: {
    reason: 'Invalid Merkle root',
    description: 'The provided Merkle root is invalid',
  },

  NFTDropMarketCore_Must_Have_Available_Supply: {
    reason: 'No available supply',
    description: 'There must be available supply to perform this action',
  },
  NFTDropMarketCore_Must_Support_Collection_Mint_Interface: {
    reason: 'Must support minting',
    description: 'The collection must support the mint interface',
  },
  NFTDropMarketCore_Must_Support_ERC721: {
    reason: 'Must support ERC721',
    description: 'The contract must support ERC721 to perform this action',
  },

  NFTDropMarketDutchAuction_Auction_Has_Not_Started_Yet: {
    reason: 'Auction not started',
    description: 'The auction has not started yet',
  },
  NFTDropMarketDutchAuction_Auction_Not_Found: {
    reason: 'Auction not found',
    description: 'The auction could not be found',
  },
  NFTDropMarketDutchAuction_Clearing_Price_Not_Reached: {
    reason: 'Auction not yet finalized',
    description: 'Funds are claimable once the dynamic period ends',
  },
  NFTDropMarketDutchAuction_Creator_Revenue_Has_Already_Been_Withdrawn: {
    reason: 'Revenue already withdrawn',
    description:
      'The creator’s revenue for this auction has already been withdrawn',
  },
  NFTDropMarketDutchAuction_End_Time_Too_Far_In_The_Future: {
    reason: 'End time too distant',
    description: 'The auction end time is too far in the future',
  },
  NFTDropMarketDutchAuction_Invalid_Nft_Recipient: {
    reason: 'Invalid recipient',
    description: 'The recipient for the NFT is invalid',
  },
  NFTDropMarketDutchAuction_Limit_Per_Account_Must_Be_Set: {
    reason: 'Limit per account required',
    description: 'A limit per account must be set for the auction',
  },
  NFTDropMarketDutchAuction_Min_Price_Must_Be_Less_Than_Max_Price: {
    reason: 'Invalid price range',
    description: 'The minimum price must be less than the maximum price',
  },
  NFTDropMarketDutchAuction_Mint_Count_Must_Be_Greater_Than_Zero: {
    reason: 'Minimum of 1 required',
    description: 'You must mint at least 1 NFT',
  },
  NFTDropMarketDutchAuction_Mint_Exceeds_Limit_Per_Account: {
    reason: 'Wallet maximum reached',
    description: 'You have reached the maximum you can mint from your wallet',
  },
  NFTDropMarketDutchAuction_Must_Have_Available_Supply: {
    reason: 'No available supply',
    description: 'There must be available supply to perform this action',
  },
  NFTDropMarketDutchAuction_Nothing_To_Rebate_At_This_Time: {
    reason: 'No funds to claim',
    description: 'There are no funds to claim at this time',
  },
  NFTDropMarketDutchAuction_Sale_Duration_Must_Be_Greater_Than_Zero: {
    reason: 'Invalid sale duration',
    description: 'The sale duration must be greater than zero',
  },
  NFTDropMarketDutchAuction_Start_Time_Must_Not_Be_In_The_Past: {
    reason: 'Start time in the past',
    description: 'The auction start time cannot be in the past',
  },
  NFTDropMarketFixedPriceSale_Cannot_Buy_More_Than_Limit: {
    reason: 'Wallet maximum reached',
    description: 'You have reached the maximum you can mint from your wallet',
  },
  NFTDropMarketFixedPriceSale_Early_Access_Not_Open: {
    reason: 'Early access not open',
    description: 'Early access for this sale is not yet open',
  },
  NFTDropMarketFixedPriceSale_Early_Access_Start_Time_Has_Expired: {
    reason: 'Early access expired',
    description: 'The start time for early access has expired',
  },
  NFTDropMarketFixedPriceSale_General_Access_Is_Open: {
    reason: 'General access is open',
    description: 'General access for this sale is now open',
  },
  NFTDropMarketFixedPriceSale_General_Access_Not_Open: {
    reason: 'General access not open',
    description: 'General access for this sale is not yet open',
  },
  // NFTDropMarketFixedPriceSale_General_Availability_Start_Time_Has_Expired: {
  //   reason: 'General availability expired',
  //   description: 'The start time for general availability has expired',
  // },
  NFTDropMarketFixedPriceSale_Invalid_Merkle_Proof: {
    reason: 'Invalid Merkle proof',
    description: 'The provided Merkle proof is invalid',
  },

  NFTDropMarketFixedPriceSale_Limit_Per_Account_Must_Be_Set: {
    reason: 'Account limit required',
    description: 'A limit per account must be set for this sale',
  },
  NFTDropMarketFixedPriceSale_Must_Be_Listed_For_Sale: {
    reason: 'Not listed for sale',
    description: 'The item must be listed for sale to perform this action',
  },
  NFTDropMarketFixedPriceSale_Must_Buy_At_Least_One_Token: {
    reason: 'Minimum of 1 required',
    description: 'You must mint at least 1 NFT',
  },
  NFTDropMarketFixedPriceSale_Must_Have_Non_Zero_Early_Access_Duration: {
    reason: 'Invalid early access duration',
    description: 'The early access duration must be greater than zero',
  },
  NFTDropMarketNode_Address_Is_Not_A_Contract: {
    reason: 'Invalid address',
    description: 'The provided address is not a contract',
  },
  NFTDropMarket_NFT_Already_Minted: {
    reason: 'NFT already minted',
    description: 'The NFT has already been minted',
  },
  NFTLimitedEditionCollection_Exceeds_Max_Token_Id: {
    reason: 'Max token ID exceeded',
    description: 'The maximum token ID for this collection has been exceeded',
  },
  NFTMarketAuction_Already_Initialized: {
    reason: 'Auction already initialized',
    description:
      'The auction has already been initialized and cannot be reinitialized',
  },
  NFTMarketBuyPrice_Cannot_Buy_At_Lower_Price: {
    reason: 'Price too low',
    description: 'You cannot buy at a price lower than the set price',
  },
  NFTMarketBuyPrice_Cannot_Buy_Unset_Price: {
    reason: 'Price not set',
    description: 'You cannot buy as the price has not been set',
  },
  NFTMarketBuyPrice_Cannot_Cancel_Unset_Price: {
    reason: 'Buy Now price already removed',
    description: 'The Buy Now price for this NFT has already been removed.',
  },
  NFTMarketBuyPrice_Only_Owner_Can_Cancel_Price: {
    reason: 'Not the owner',
    description: 'Only the owner can cancel the set price',
  },
  NFTMarketBuyPrice_Only_Owner_Can_Set_Price: {
    reason: 'Not the owner',
    description: 'Only the owner can set the price',
  },
  NFTMarketBuyPrice_Only_Owner_Can_Update_Nft: {
    reason: 'Not the owner',
    description: 'Only the owner can update this NFT',
  },
  NFTMarketBuyPrice_Price_Already_Set: {
    reason: 'Price already set',
    description: 'The price for this NFT has already been set',
  },
  NFTMarketBuyPrice_Price_Too_High: {
    reason: 'Price too high',
    description: 'The set price is too high',
  },
  NFTMarketBuyPrice_Seller_Mismatch: {
    reason: 'Seller mismatch',
    description: 'The seller does not match the current owner',
  },
  NFTMarketCore_Can_Not_Update_Unlisted_Nft: {
    reason: 'NFT not listed',
    description: 'You cannot update an NFT that is not listed',
  },
  NFTMarketCore_Seller_Not_Found: {
    reason: 'Seller not found',
    description: 'The seller for this NFT could not be found',
  },
  NFTMarketFees_Invalid_Protocol_Fee: {
    reason: 'Invalid protocol fee',
    description: 'The protocol fee specified is invalid',
  },
  NFTMarketFees_Market_Utils_Is_Not_A_Contract: {
    reason: 'Invalid market utilities',
    description: 'The market utilities provided are not a valid contract',
  },
  NFTMarketNode_Address_Is_Not_A_Contract: {
    reason: 'Invalid address',
    description: 'The provided address is not a contract',
  },
  NFTMarketOffer_Cannot_Be_Made_While_In_Auction: {
    reason: 'Offer during auction',
    description: 'An offer cannot be made while the NFT is in an auction',
  },
  NFTMarketOffer_Offer_Below_Min_Amount: {
    reason: 'Offer too low',
    description: 'The offer is below the minimum amount required',
  },
  NFTMarketOffer_Offer_Expired: {
    reason: 'Offer expired',
    description: 'The offer has expired and is no longer valid',
  },
  NFTMarketOffer_Offer_From_Does_Not_Match: {
    reason: 'Offer mismatch',
    description: 'The offer does not match the expected parameters',
  },
  NFTMarketOffer_Offer_Must_Be_At_Least_Min_Amount: {
    reason: 'Offer too low',
    description: 'The offer must be at least the minimum amount',
  },
  NFTMarketReserveAuction_Already_Listed: {
    reason: 'Already listed',
    description: 'This NFT is already listed',
  },
  NFTMarketReserveAuction_Bid_Must_Be_At_Least_Min_Amount: {
    reason: 'Bid price too low',
    description: 'The bid amount is lower than active bid amount',
  },
  NFTMarketReserveAuction_Cannot_Bid_Lower_Than_Reserve_Price: {
    reason: 'Bid price too low',
    description: 'The bid amount is lower than the reserve price',
  },
  NFTMarketReserveAuction_Cannot_Bid_On_Ended_Auction: {
    reason: 'Auction ended',
    description: 'You cannot bid on an auction that has already ended',
  },
  NFTMarketReserveAuction_Cannot_Bid_On_Nonexistent_Auction: {
    reason: 'Auction nonexistent',
    description: 'You cannot bid on an auction that does not exist',
  },
  NFTMarketReserveAuction_Cannot_Finalize_Already_Settled_Auction: {
    reason: 'Auction already settled',
    description:
      'This auction has already been settled and the NFT transferred to the new owner’s wallet.',
  },
  NFTMarketReserveAuction_Cannot_Finalize_Auction_In_Progress: {
    reason: 'Auction in progress',
    description: 'You cannot finalize an auction that is still in progress',
  },
  NFTMarketReserveAuction_Cannot_Rebid_Over_Outstanding_Bid: {
    reason: 'You’re already winning',
    description: 'You have already placed a bid on this auction.',
  },
  NFTMarketReserveAuction_Cannot_Update_Auction_In_Progress: {
    reason: 'Auction in progress',
    description: 'You cannot update an auction that is still in progress',
  },
  NFTMarketReserveAuction_Cannot_Update_Nft_While_Auction_In_Progress: {
    reason: 'Auction in progress',
    description: 'You cannot update an NFT while an auction is in progress',
  },

  NFTMarketReserveAuction_Not_Matching_Seller: {
    reason: 'Seller mismatch',
    description: 'The seller does not match the current owner of the NFT',
  },

  NFTMarketReserveAuction_Price_Already_Set: {
    reason: 'Price already set',
    description: 'The price on this auction has already been set',
  },
  NFTMarketRouterList_Buy_Price_Set_But_Should_Set_Buy_Price_Is_False: {
    reason: 'Incorrect buy price setting',
    description: 'A buy price is set but should not be according to settings',
  },
  NFTMarketRouterList_Duration_Set_Without_Reserve_Price: {
    reason: 'Duration without reserve price',
    description: 'A duration is set without setting a reserve price',
  },
  NFTMarketRouterList_Missing_World_Associations: {
    reason: 'Missing world associations',
    description: 'There are missing associations with the world in the listing',
  },
  NFTMarketRouterList_Must_Set_Reserve_Buy_Price_Or_World: {
    reason: 'Set reserve, buy price, or world',
    description:
      'You must set either a reserve price, buy price, or associate with a world',
  },
  NFTMarketRouterList_Must_Set_Reserve_Or_Buy_Price: {
    reason: 'Must set a Reserve or Buy Now price',
    description: 'You must set a Reserve or Buy Now price',
  },
  NFTMarketRouterList_Schedule_Set_Without_Prices: {
    reason: 'Schedule set without prices',
    description: 'A schedule is set but no prices have been defined',
  },
  NFTMarketRouterList_Token_Ids_Not_Set: {
    reason: 'Token IDs not set',
    description: 'Token IDs must be set for this operation',
  },
  NFTMarketRouterList_World_Id_Set_Without_Prices: {
    reason: 'World ID set without prices',
    description: 'A world ID is set but no prices have been defined',
  },
  NFTMarketScheduling_Sale_Starts_At_Already_Set: {
    reason: 'Sale start time already set',
    description: 'The sale start time has already been set',
  },
  NFTMarketScheduling_Sale_Starts_At_Is_In_Future: {
    reason: 'Sale start time in the future',
    description: 'The sale start time is set in the future',
  },
  NFTMarketScheduling_Sale_Starts_At_Is_In_Past: {
    reason: 'Sale start time in the past',
    description: 'The sale start time is set in the past',
  },
  NFTMarketScheduling_Sale_Starts_At_Too_Far_In_The_Future: {
    reason: 'Sale start time too far in future',
    description: 'The sale start time is too far in the future',
  },
  Ownable_Already_Initialized: {
    reason: 'Already initialized',
    description: 'The contract has already been initialized',
  },
  Ownable_Not_Pending_Owner: {
    reason: 'Not pending owner',
    description: 'You are not the pending owner required for this operation',
  },
  Ownable_Sender_Is_Not_Owner: {
    reason: 'Not the owner',
    description: 'You are not the owner required for this operation',
  },
  Ownable_Transfer_To_Already_Initiated: {
    reason: 'Transfer already initiated',
    description: 'The transfer to this address has already been initiated',
  },

  RevealableCollection_Already_Revealed: {
    reason: 'Already revealed',
    description: 'The collection has already been revealed',
  },
  RouteCallLibrary_Call_Failed_Without_Revert_Reason: {
    reason: 'Call failed',
    description: 'The call failed without a revert reason',
  },
  RouterContextDouble_Router_Not_A_Contract: {
    reason: 'Invalid router address',
    description: 'The router address is not a contract',
  },
  RouterContextSingle_Address_Is_Not_A_Contract: {
    reason: 'Invalid address',
    description: 'The provided address is not a contract',
  },
  SafeCastOverflowedUintDowncast: {
    reason: 'Overflowed integer',
    description: 'The integer value overflowed during downcasting',
  },

  SupplyLock_Action_Disallowed_While_Supply_Is_Locked: {
    reason: 'Supply locked',
    description: 'This action is disallowed while the supply is locked',
  },
  SupplyLock_Caller_Is_Not_Supply_Lock_Holder: {
    reason: 'Not supply lock holder',
    description: 'The caller is not the holder of the supply lock',
  },
  SupplyLock_Existing_Lock_Has_Already_Expired: {
    reason: 'Lock expired',
    description: 'The existing supply lock has already expired',
  },
  SupplyLock_Expiration_Time_In_The_Past: {
    reason: 'Expiration time in past',
    description: 'The expiration time for the supply lock is in the past',
  },
  SupplyLock_Expiration_Time_Too_Far_In_The_Future: {
    reason: 'Expiration time too far',
    description:
      'The expiration time for the supply lock is too far in the future',
  },
  TimeLimitedCollection_Mint_End_Time_Must_Be_In_The_Future: {
    reason: 'End time in the future',
    description: 'The end time for minting must be set in the future',
  },
  TimeLimitedCollection_Mint_End_Time_Too_Far_In_The_Future: {
    reason: 'End time too far',
    description: 'The end time for minting is set too far in the future',
  },
  TimeLimitedCollection_Minting_Has_Ended: {
    reason: 'Minting ended',
    description: 'The time period for minting has ended',
  },
  TokenLimitedCollection_Max_Token_Id_May_Not_Be_Cleared: {
    reason: 'Cannot clear max token ID',
    description: 'The maximum token ID for the collection may not be cleared',
  },
  TokenLimitedCollection_Max_Token_Id_May_Not_Increase: {
    reason: 'Max token ID cannot increase',
    description: 'The maximum token ID for the collection may not increase',
  },
  TokenLimitedCollection_Max_Token_Id_Must_Be_Greater_Than_Current_Minted_Count:
    {
      reason: 'Invalid max token ID',
      description:
        'The maximum token ID must be greater than the current minted count',
    },
  TokenLimitedCollection_Max_Token_Id_Must_Not_Be_Zero: {
    reason: 'Max token ID required',
    description: 'The maximum token ID must not be zero',
  },
  TxDeadline_Tx_Deadline_Expired: {
    reason: 'Transaction deadline expired',
    description: 'The deadline for this transaction has expired',
  },
  WorldSplit_No_ERC1155_Balance_To_Withdraw: {
    reason: 'No ERC1155 balance',
    description: 'There is no ERC1155 balance to withdraw',
  },
  WorldSplit_No_ERC20_Balance_To_Withdraw: {
    reason: 'No ERC20 balance',
    description: 'There is no ERC20 balance to withdraw',
  },
  WorldSplit_No_ETH_Balance_To_Withdraw: {
    reason: 'No ETH balance',
    description: 'There is no ETH balance to withdraw',
  },
  WorldSplit_Owner_Cannot_Be_Zero_Address: {
    reason: 'Owner cannot be zero address',
    description: 'The owner cannot be the zero address',
  },
  WorldsAllowlistBySeller_Address_0_May_Not_Be_Added: {
    reason: 'Address zero not allowed',
    description: 'The zero address may not be added to the allowlist',
  },
  WorldsAllowlistBySeller_No_Sellers_Provided: {
    reason: 'No sellers provided',
    description: 'No sellers have been provided for the allowlist',
  },
  WorldsAllowlistBySeller_Seller_Already_On_Allowlist: {
    reason: 'Seller already listed',
    description: 'The seller is already on the allowlist',
  },
  WorldsAllowlistBySeller_Seller_Not_Allowed: {
    reason: 'Seller not allowed',
    description: 'The seller is not allowed on the allowlist',
  },
  WorldsAllowlistBySeller_Take_Rate_Too_Low: {
    reason: 'Take rate too low',
    description: 'The take rate is too low',
  },
  WorldsAllowlistBySignature_Invalid_Approval_Type: {
    reason: 'Invalid approval type',
    description: 'The approval type provided is invalid',
  },
  WorldsAllowlistBySignature_Signer_Is_Required: {
    reason: 'Signer required',
    description: 'A signer is required for this operation',
  },
  WorldsAllowlistBySignature_Tx_Deadline_Expired: {
    reason: 'Transaction deadline expired',
    description: 'The transaction deadline has expired',
  },
  WorldsAllowlistBySignature_Tx_Deadline_Too_Far_In_Future: {
    reason: 'Deadline too far',
    description: 'The transaction deadline is set too far in the future',
  },
  WorldsAllowlist_List_Type_Does_Not_Support_Listing_Identifier: {
    reason: 'Unsupported list type',
    description: 'The list type does not support the listing identifier',
  },
  WorldsAllowlist_Take_Rate_Above_Max: {
    reason: 'Take rate too high',
    description: 'The take rate is above the maximum allowed',
  },
  WorldsInventoryByNft_Not_In_A_World: {
    reason: 'NFT not in a world',
    description: 'The NFT is not listed in any world',
  },
  WorldsInventoryBySplit_Not_A_Valid_Split: {
    reason: 'Invalid split',
    description: 'The split is not valid',
  },
  WorldsInventoryBySplit_Not_Listed_In_A_World: {
    reason: 'Not listed in a world',
    description: 'The item is not listed in any world',
  },
  WorldsInventoryBySplit_Split_Implementation_Not_A_Contract: {
    reason: 'Invalid split implementation',
    description: 'The split implementation is not a valid contract',
  },
  WorldsManagement_Already_Initialized: {
    reason: 'Already initialized',
    description: 'The worlds management has already been initialized',
  },
  WorldsManagement_WorldId_Exceeds_Max_Network_World_Id: {
    reason: 'World ID too high',
    description: 'The world ID exceeds the maximum for the network',
  },
  WorldsMetadata_Name_Already_Set: {
    reason: 'Name already set',
    description: 'The name has already been set',
  },

  WorldsMetadata_Svg_Template_Missing_WorldId_Placeholder: {
    reason: 'Missing World ID placeholder',
    description: 'The SVG template is missing a World ID placeholder',
  },
  WorldsNftNode_Worlds_NFT_Is_Not_A_Contract: {
    reason: 'Invalid NFT contract',
    description: 'The Worlds NFT provided is not a valid contract',
  },
  WorldsNotImplemented_Function_Not_Implemented: {
    reason: 'Function not implemented',
    description: 'This function has not been implemented',
  },
  WorldsPaymentInfo_Cannot_Set_Zero_Payment_Address: {
    reason: 'Zero payment address not allowed',
    description: 'You cannot set a zero payment address',
  },
  WorldsPaymentInfo_Exceeds_Max_World_Take_Rate: {
    reason: 'Take rate too high',
    description: 'The world take rate exceeds the maximum allowed',
  },
  WorldsPaymentInfo_Payment_Address_Already_Set: {
    reason: 'Must be different from current address',
    description: 'You must set a new payment address',
  },
  WorldsTransfer2Step_Caller_Not_Token_Owner_Or_Approved: {
    reason: 'Must be owner to transfer ownership',
    description: 'You must be the owner to transfer ownership',
  },
  WorldsTransfer2Step_Cannot_Transfer_To_Current_Owner: {
    reason: 'Cannot transfer to current owner',
    description: 'You cannot transfer to the current owner',
  },
  WorldsTransfer2Step_Not_Pending_Owner_For_Token_Id: {
    reason: 'Must be pending owner to accept transfer',
    description: 'You must be the pending owner to accept transfer',
  },
  WorldsTransfer2Step_Transfer_To_Already_Initiated: {
    reason: 'Transfer already initiated',
    description: 'Transfer to this address has already been initiated',
  },
  WorldsUserRoles_No_User_Role_Actions: {
    reason: 'No user role actions',
    description: 'There are no user role actions available',
  },
  WorldsUserRoles_Sender_Does_Not_Have_Admin_User_Role: {
    reason: 'Not an admin user',
    description: 'The sender does not have the admin user role',
  },
  WorldsUserRoles_Sender_Does_Not_Have_Editor_User_Role: {
    reason: 'Not an editor user',
    description: 'The sender does not have the editor user role',
  },
};

export const INVALID_ADDRESS = 'Address is invalid';
export const INVALID_HEX = 'Invalid hex value';

/**
 * TODO (wagmi-v2): explore InsufficientFundsError
 * and seeing if we can extract the error message
 */
export const INSUFFICIENT_FUNDS_MESSAGE =
  'The total cost (gas * gas fee + value) of executing this transaction exceeds the balance of the account.';

export const INSUFFICIENT_FUNDS_ERROR = {
  reason: 'Insufficient funds',
  description: 'You do not have enough funds to complete this transaction',
} as const;
