import { z } from 'zod';

import { INVALID_ADDRESS, INVALID_HEX } from 'copy/errors';

import { getAddress, isHex } from 'utils/address';

export const hexValueSchema = z.string().transform((value, ctx) => {
  if (isHex(value, { strict: true })) {
    return value;
  }
  ctx.addIssue({
    code: z.ZodIssueCode.custom,
    message: INVALID_HEX,
  });
  return z.NEVER;
});

export const addressValueSchema = z.string().transform((address, ctx) => {
  try {
    return getAddress(address);
  } catch {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: INVALID_ADDRESS,
    });
    return z.NEVER;
  }
});
