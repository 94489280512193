import { styled } from '@f8n-frontend/stitches';
import * as RadixTabs from '@radix-ui/react-tabs';
import { useLayoutEffect, useRef } from 'react';

import { PULSE_TARGET } from 'components/Pulse';
import { countCss } from 'css/button';

const Content = styled(RadixTabs.Content, {
  '&:focus-visible': {
    outline: 'none',
  },
});

const Count = styled('div', countCss, {
  height: 20,
  backgroundColor: '$black5',
  fontWeight: '$semibold',
  lineHeight: '20px',
  fontSize: '10px',
});

const Trigger = styled(RadixTabs.Trigger, {
  flexShrink: 0,
  textDecoration: 'none',

  // resets
  outline: 'none',
  cursor: 'pointer',

  // flex container
  display: 'flex',
  alignItems: 'center',

  // typography
  fontWeight: '$semibold',
  fontFamily: '$body',
  fontSize: '$1',
  lineHeight: '$1',

  // sizing
  height: '$formElement0',
  paddingX: '$4',
  paddingY: '$2',

  background: 'transparent',
  border: '1px solid transparent',
  borderRadius: '$round',
  transition:
    'background-color $1 $ease, border $1 $ease, box-shadow $1 $ease, color $1 $ease, outline $1 $ease, transform $1 $ease',

  [`${Count}`]: {
    paddingX: '$2',
    marginLeft: '$1',
    marginRight: '-$2',
  },

  [PULSE_TARGET.selector]: {
    marginLeft: '-$1',
    marginRight: '$1',
  },

  '&[data-state="active"]': {
    backgroundColor: '$black5',
  },

  backgroundColor: 'transparent',
  color: '$black100',

  '@hover': {
    '&:hover': {
      backgroundColor: '$black5',
    },
  },
  '&:active': {
    backgroundColor: '$black5',
    transform: 'translate3d(0, 2px, 0)',
  },
  '&:focus-visible': {
    borderColor: '$black100',
    outline: '4px solid $black30',
  },
  '&:disabled': {
    color: '$black40',
    '@hover': {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '&:active': {
      transform: 'none',
    },
  },

  variants: {
    variant: {
      normal: {},
      outline: {
        border: '1px solid $black5-solid',
      },
    },
  },
});

const List = styled(RadixTabs.List, {
  display: 'flex',
  paddingY: '$4',
  gap: '$1',
  scrollbarWidth: 'thin',

  '@bp0': {
    paddingY: '$6',
  },

  variants: {
    centered: {
      true: {
        justifyContent: 'center',
      },
      false: {},
    },
  },

  defaultVariants: {
    centered: false,
  },
});

const Container = styled('div', {
  maxWidth: '100vw',
  overflowY: 'scroll',
  background: '$white90',
  backdropFilter: 'saturate(150%) blur(20px) brightness(1.5)',
  boxShadow: '0 -1px 0 0 $colors$black5, 0 1px 0 0 $colors$black5',
});

type EnableScrollToTopProps = {
  /** value passed to Tabs.Root */
  value: string | undefined;
};

function EnableScrollToTop(props: EnableScrollToTopProps) {
  const valueRef = useRef<string | null>(null);

  useLayoutEffect(() => {
    // Early return if no value
    if (!props.value) return;

    // Avoid scroll on first render
    if (!valueRef.current) {
      valueRef.current = props.value;
      return;
    }

    // Get the list of tabs
    const tabListEl = document.querySelector(`[role="tablist"]`);

    // Return early if no tabs
    if (!tabListEl) return;

    const timeout = window.setTimeout(() => {
      try {
        tabListEl.scrollIntoView({
          behavior: 'smooth',
        });
      } catch (error) {
        // intentionally do nothing if scrollIntoView isn't supported
      }
    }, 0);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [props.value]);

  return null;
}

const Tabs = {
  /** Styled box. Place the `Tabs.List` inside of here to make it look nice. */
  Container,
  /** To show the number of items inside a tab */
  Count,
  /** Content inside a tab */
  Content,
  /** Should contain a selection of `Tabs.Trigger` */
  List,
  /** Provides a context for the `Content` and `Trigger` components inside of it */
  Root: RadixTabs.Root,
  /** Used to open a tab */
  Trigger,
  /** Used to opt-in to srolling to the top of tabs when value changes */
  EnableScrollToTop,
};

export default Tabs;
