import { CloseIcon, ArrowLeftIcon } from '@f8n/icons';
import {
  darkMode,
  styled,
  keyframes,
  transitions,
} from '@f8n-frontend/stitches';
import * as Dialog from '@radix-ui/react-dialog';
import { ComponentProps, CSSProperties } from '@stitches/react';
import { forwardRef, TouchEvent, useEffect } from 'react';

import Button from 'components/base/Button';
import { H3Heading } from 'components/base/Heading';
import Tabs from 'components/base/Tabs';
import Text from 'components/base/Text';

import { formatInteger } from 'utils/formatters';

export const MODAL_BODY_IDENTIFIER = 'body';

const MODAL_Z_INDEX_STACK = [999, 1000, 1001] as const;

const modalAnimations = {
  overlay: {
    open: transitions.fadeIn,
    close: transitions.fadeOut,
  },
  window: {
    open: keyframes({
      from: { opacity: 0, transform: 'translate3d(0, 5vh, 0)' },
      to: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    }),
    close: keyframes({
      from: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
      to: { opacity: 0, transform: 'translate3d(0, 5vh, 0)' },
    }),
  },
  windowMobile: {
    open: transitions.longFadeInUp,
    close: transitions.longFadeInDown,
  },
};

/**
 * Primary overlay rendered behind the Modal Window
 */
const BlurOverlay = styled(Dialog.Overlay, {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: MODAL_Z_INDEX_STACK[0],

  "&[data-state='open']": {
    animation: `${modalAnimations.overlay.open} $transitions$2 $transitions$ease forwards`,
  },
  "&[data-state='closed']": {
    animation: `${modalAnimations.overlay.close} $transitions$2 $transitions$ease forwards`,
  },

  variants: {
    variant: {
      blur: {
        background: '$black60',
        backdropFilter: 'blur(10px)',
      },
      white: {
        background: '$white100',
      },
    },
  },

  defaultVariants: {
    variant: 'blur',
  },
});

/**
 * Secondary Overlay, responsible for positioning the Modal Window
 */
const PositionOverlay = styled(Dialog.Overlay, {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: MODAL_Z_INDEX_STACK[1],

  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',

  '@bp1-max': {
    '& > *': {
      width: '100%',
    },
  },

  '@bp1': {
    alignItems: 'center',
    justifyContent: 'center',
  },

  // This prevents the PositionOverlay from being unmounted until after this animation has completed
  // The animation does nothing, but is necessary to allow the animations on the Modal Window to complete
  "&[data-state='closed']": {
    animation: `${transitions.fakeAnimaton} $transitions$2 $transitions$ease forwards`,
  },

  variants: {
    fullScreen: {
      true: {
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  },
});

/**
 * Body, in the center of the Modal Window
 */
const Body = styled('div', {
  flex: 1,
  overflow: 'auto',
  scrollbarWidth: 'thin',
});

/**
 * Footer, rendered at the bottom of the Modal Window
 */
const Footer = styled('div', {
  padding: '$4',
  display: 'flex',
  alignItems: 'center',
  background: '$white80',
  backdropFilter: 'blur(10px)',

  bottom: 0,
  left: 0,
  width: '100%',
  position: 'absolute',

  [`${Button}:only-child`]: {
    width: '100%',
  },

  variants: {
    justify: {
      stretch: {
        gap: '$4',
        [`${Button}`]: {
          flex: 1,
        },
      },
      'space-between': {
        justifyContent: 'space-between',
      },
    },
  },

  defaultVariants: {
    justify: 'space-between',
  },
});

type HeaderProps = {
  children?: React.ReactNode;
  onBackClick?: (() => void) | null;
  title?: string;
};

/**
 * Header, rendered at the top of the Modal Window
 */
function Header(props: HeaderProps) {
  // TODO: document usage: if children, do not pass title and vice versa
  const { onBackClick, title, children } = props;

  return (
    <HeaderPrimitive css={{ zIndex: 1 }}>
      {!onBackClick && !title && !children && <ShadowButton />}
      {onBackClick && (
        <Button
          icon="standalone"
          onClick={onBackClick}
          size={0}
          variant="ghost"
          type="button"
        >
          <ArrowLeftIcon />
        </Button>
      )}
      {children}
      {title && (
        <>
          {!onBackClick && <ShadowButton />}
          <Dialog.Title asChild>
            <H3Heading weight="semibold">{title}</H3Heading>
          </Dialog.Title>
        </>
      )}
      <Close type="button">
        <CloseIcon />
      </Close>
    </HeaderPrimitive>
  );
}

/**
 * If the Modal contains no Header, this is used as a fallback
 */
function DefaultModalHeader() {
  return (
    <PinTopRight>
      <Close
        css={{
          background: '$white80',
          backdropFilter: 'blur(10px)',

          [darkMode]: {
            backgroundColor: '$black20',
            '@hover': {
              '&:hover': {
                color: '$white100',
                boxShadow: '$regular1',
                backgroundColor: '$black100',
                transform: 'translate3d(0, -1px, 0)',
              },
            },
          },
        }}
      >
        <CloseIcon />
      </Close>
    </PinTopRight>
  );
}

// This is needed to visually align the title in the middle when there's
// nothing within the left hand-side of the header.
const ShadowButton = styled('div', {
  width: '$formElement0',
  height: '$formElement0',
});

const HeaderPrimitive = styled('div', {
  padding: '$4',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  background: '$white80',
  backdropFilter: 'blur(10px)',

  top: 0,
  left: 0,
  width: '100%',
  position: 'absolute',

  [`& > ${Tabs.List}`]: {
    overflowY: 'auto',
    marginLeft: '-$4',
  },

  [`& > ${Tabs.List} > *:first-child`]: {
    marginLeft: '$4',
  },
  // fixes border getting cut off when :focus-visible is triggered
  [`& > ${Tabs.List} > *:last-child`]: {
    marginRight: '$1',
  },
});

const Close = styled(Dialog.Close, Button, {
  flexShrink: 0,
  defaultVariants: {
    size: 0,
    icon: 'standalone',
    variant: 'ghost',
  },
});

const PinTopRight = styled('div', {
  zIndex: 1,
  top: '$4',
  right: '$4',
  position: 'absolute',
});

const ContentBlock = styled('div', {
  textAlign: 'left',
});

const ModalWindowBase = styled(Dialog.Content, {
  $$headerHeight: '68px',
  $$footerHeight: '88px',
  position: 'relative',

  width: '100vw',
  maxHeight: '80vh',
  background: '$white100',
  borderTopLeftRadius: '$4',
  borderTopRightRadius: '$4',
  overflow: 'hidden',
  boxShadow: '$regular2',

  display: 'flex',
  flexDirection: 'column',

  '@bp1-max': {
    transform: 'translate3d(0, 100%, 0)',
    "&[data-state='open']": {
      animation: `${modalAnimations.windowMobile.open} $transitions$2 $transitions$ease forwards`,
    },
    "&[data-state='closed']": {
      animation: `${modalAnimations.windowMobile.close} $transitions$2 $transitions$ease forwards`,
    },
  },

  '@bp1': {
    maxWidth: 480,
    borderRadius: '$4',
    opacity: 0,

    "&[data-state='open']": {
      animation: `${modalAnimations.window.open} $transitions$2 $transitions$ease forwards`,
      animationDelay: '125ms',
    },
    "&[data-state='closed']": {
      animation: `${modalAnimations.window.close} $transitions$2 $transitions$ease forwards`,
    },
  },

  [`${HeaderPrimitive}`]: {
    boxShadow: '0px 0px 0px 1px $colors$black5',
    height: '$$headerHeight',
  },

  [`${Tabs.Content}`]: {
    height: '100%',
  },

  [`${Footer}`]: {
    padding: '$5',
    height: '$$footerHeight',
    boxShadow: '0px -1px 0px 0px $colors$black5',
  },

  '&:focus': {
    outline: 'none',
  },

  variants: {
    hasBodyBlocks: { true: {}, false: {} },
    hasHeader: { true: {}, false: {} },
    hasFooter: { true: {}, false: {} },
    size: {
      0: {
        [`${Body}`]: {
          paddingBottom: 'env(safe-area-inset-bottom)',
        },
      },
      1: {
        [`${Body}`]: {
          paddingBottom: 'calc($6 + env(safe-area-inset-bottom))',
        },
      },
      2: {
        [`${Body}`]: {
          paddingBottom: 'calc($8 + env(safe-area-inset-bottom))',
        },
      },
    },
    height: {
      full: {
        height: '100%',
      },
      normal: {
        height: 700,
      },
    },
  },
  compoundVariants: [
    // With Body blocks
    {
      hasBodyBlocks: false,
      size: 1,
      css: {
        [`${Body}`]: {
          paddingX: '$6',
          paddingTop: '$7',
        },
      },
    },
    {
      hasBodyBlocks: false,
      size: 2,
      css: {
        [`${Body}`]: {
          paddingX: '$8',
          paddingTop: '$8',
        },
      },
    },
    {
      hasBodyBlocks: true,
      size: 1,
      css: {
        [`${ContentBlock}`]: {
          paddingX: '$6',
          paddingY: '$5',
        },
      },
    },
    {
      hasBodyBlocks: true,
      size: 2,
      css: {
        [`${ContentBlock}`]: {
          paddingX: '$8',
          paddingTop: '$8',
        },
      },
    },
    // With Header
    {
      hasBodyBlocks: true,
      hasHeader: true,
      css: {
        [`${Body}`]: {
          paddingTop: '$$headerHeight',
        },
      },
    },
    {
      size: 0,
      hasHeader: true,
      css: {
        [`${Body}`]: {
          paddingTop: '$$headerHeight',
        },
      },
    },
    {
      size: 1,
      hasHeader: true,
      hasBodyBlocks: false,
      css: {
        [`${Body}`]: {
          paddingTop: 'calc($$headerHeight + $6)',
        },
      },
    },
    {
      size: 2,
      hasHeader: true,
      hasBodyBlocks: false,
      css: {
        [`${Body}`]: {
          paddingTop: 'calc($$headerHeight + $8)',
        },
      },
    },
    // With Footer
    {
      size: 0,
      hasFooter: true,
      css: {
        [`${Body}`]: {
          paddingBottom: 'calc($$footerHeight + env(safe-area-inset-bottom))',
        },
      },
    },
    {
      size: 1,
      hasFooter: true,
      css: {
        [`${Body}`]: {
          paddingBottom:
            'calc($$footerHeight + $6 + env(safe-area-inset-bottom))',
        },
      },
    },
    {
      size: 2,
      hasFooter: true,
      css: {
        [`${Body}`]: {
          paddingBottom:
            'calc($$footerHeight + $8 + env(safe-area-inset-bottom))',
        },
      },
    },
  ],
  defaultVariants: {
    hasBodyBlocks: false,
    size: 1,
  },
});

export type ModalWindowBaseProps = ComponentProps<typeof ModalWindowBase>;
type BaseContentProps = Dialog.DialogContentProps & {
  children: React.ReactNode;
  footer?: React.ReactNode;
  hasBodyBlocks?: ModalWindowBaseProps['hasBodyBlocks'];
  header?: React.ReactNode;
  maxWidth?: CSSProperties['maxWidth'];
  maxHeight?: CSSProperties['maxHeight'];
  size?: ModalWindowBaseProps['size'];
  height?: ModalWindowBaseProps['height'];
};

export type ContentProps = BaseContentProps & Partial<UnmountListenerProps>;

const ModalWindow = forwardRef(function ModalWindow(
  props: ContentProps,
  ref: React.Ref<HTMLDivElement>
) {
  const {
    children,
    footer,
    header,
    maxWidth,
    maxHeight,
    size = 1,
    height,
    ...contentProps
  } = props;
  return (
    <ModalWindowBase
      ref={ref}
      css={{ '@bp1': { maxWidth, maxHeight } }}
      hasFooter={Boolean(footer)}
      hasHeader={Boolean(header)}
      size={size}
      height={height}
      {...contentProps}
    >
      {header || <DefaultModalHeader />}
      <Body data-modal-el={MODAL_BODY_IDENTIFIER}>{children}</Body>
      {footer}
    </ModalWindowBase>
  );
});

type UnmountListenerProps = {
  onUnmount: () => void;
};

/**
 * Works around a bug in Radix where onAnimationEnd is not called when the modal is closed.
 *
 * @see https://github.com/radix-ui/primitives/issues/1020
 */
function UnmountListener(props: UnmountListenerProps) {
  useEffect(() => {
    return () => {
      props.onUnmount();
    };
  }, []);

  return null;
}

/**
 * This is a convenience wrapper to give most modals less boilerplate.
 * It encapsulates the Potal, Overlay, and Window components.
 */
function ModalWindowComposition(props: ContentProps) {
  const { onUnmount, ...modalProps } = props;

  return (
    <Dialog.Portal>
      <BlurOverlay />
      <PositionOverlay>
        <ModalWindow {...modalProps} />
        {onUnmount && <UnmountListener onUnmount={onUnmount} />}
      </PositionOverlay>
    </Dialog.Portal>
  );
}

function ModalWindowCompositionFullscreen(props: ContentProps) {
  const { children } = props;
  return (
    <Dialog.Portal>
      <BlurOverlay variant="white" />
      <PositionOverlay fullScreen>
        <FullScreenContainer data-modal-el={MODAL_BODY_IDENTIFIER}>
          {children}
        </FullScreenContainer>
        {props.onUnmount && <UnmountListener onUnmount={props.onUnmount} />}
      </PositionOverlay>
    </Dialog.Portal>
  );
}

const FullScreenContainer = styled(Dialog.Content, {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingX: '$5',
  outline: 'none',
  opacity: 0,
  width: '100%',

  "&[data-state='open']": {
    animation: `${modalAnimations.window.open} $transitions$2 $transitions$ease forwards`,
    animationDelay: '125ms',
  },
  "&[data-state='closed']": {
    animation: `${modalAnimations.window.close} $transitions$2 $transitions$ease forwards`,
  },
});

const ContentStack = styled('div', {
  gap: '$2',
  display: 'flex',
  flexDirection: 'column',
});

// BodyTitle
type BodyTitleContainerProps = ComponentProps<typeof BodyTitleContainer>;

type BodyTitleProps = {
  align?: BodyTitleContainerProps['align'];
  description?: string | React.ReactNode;
  size?: BodyTitleContainerProps['size'];
  title?: string | React.ReactNode;
};

function BodyTitle(props: BodyTitleProps) {
  const { align, title, description, size } = props;
  if (!title && !description) {
    return null;
  }
  return (
    <BodyTitleContainer size={size} align={align}>
      {title && (
        <Dialog.Title asChild>
          <H3Heading lineHeight={1} weight="medium">
            {title}
          </H3Heading>
        </Dialog.Title>
      )}
      {description && (
        <Dialog.Description asChild>
          <Text color="dim" lineHeight={2} weight="regular">
            {description}
          </Text>
        </Dialog.Description>
      )}
    </BodyTitleContainer>
  );
}

const BodyTitleContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  variants: {
    align: {
      left: {
        alignItems: 'flex-start',
        textAlign: 'left',
      },
      center: {
        alignItems: 'center',
        textAlign: 'center',
      },
      right: {
        alignItems: 'flex-end',
        textAlign: 'right',
      },
    },
    size: {
      1: {
        gap: '$1',
        [`${Text}`]: {
          fontSize: '$2',
        },
        [`${H3Heading}`]: {
          fontSize: '$4',
          letterSpacing: '$-1',
        },
      },
      2: {
        gap: '$2',
        [`${Text}`]: {
          fontSize: '$2',
        },
        [`${H3Heading}`]: {
          fontSize: '$4',
          letterSpacing: '$-1',
          '@bp2': {
            fontSize: '$5',
            letterSpacing: '$-2',
          },
        },
      },
    },
  },
  compoundVariants: [
    {
      align: 'left',
      size: 2,
      css: {
        [`${H3Heading}`]: {
          '@bp2': {
            // This is to avoid the rare case of the title touching the close button
            paddingRight: '28px',
          },
        },
      },
    },
  ],
  defaultVariants: {
    align: 'left',
    size: 2,
  },
});

function SelectedCount(props: { count: number }) {
  const { count } = props;

  if (!count) {
    return <div />;
  }

  return <Text color="strong">{formatInteger(props.count)} selected</Text>;
}

// Utils
const onTouchEnd = (_ev: TouchEvent<unknown>) => {
  if (typeof document !== 'undefined') {
    document.body.style.pointerEvents = '';
  }
};

// Default props
ModalWindowComposition.defaultProps = {
  onTouchEnd: (ev: TouchEvent) => ev.stopPropagation(),
};
BlurOverlay.defaultProps = {
  onTouchEnd,
};
Dialog.Close.defaultProps = {
  onTouchEnd,
};

const Modal = {
  /** All Modal sub-components must be wrapped by this */
  Root: Dialog.Root,
  // Use this to open a Modal
  Trigger: Dialog.Trigger,
  // Most modals should use this to configure the Modal Window
  Content: ModalWindowComposition,
  // Full screen content
  FullScreen: ModalWindowCompositionFullscreen,
  // Should be passed as a `header` prop to `Content`
  Header,
  // Should be passed as a `footer` prop to `Content`
  Footer,

  // UI components to use inside the Modal Body
  ContentBlock,
  ContentStack,
  BodyTitle,
  Close: Dialog.Close,

  // UI components to use inside the Modal Footer
  SelectedCount,

  // If you need more control, use these lower-level components:
  BlurOverlay,
  Portal: Dialog.Portal,
  PositionOverlay,
  Window: ModalWindow,
  UnmountListener,
};

export default Modal;
