import { slugify } from 'underscore.string';
import { z } from 'zod';

import { addressValueSchema } from './shared';
import { chainIdSchema } from './url/chain';

export const collectionFilterSchema = z.object({
  contractAddress: addressValueSchema,
  chainId: chainIdSchema,
});

export const collectionSymbolSchema = z
  .string()
  .transform((val) => slugify(val.slice(0, 18)).toUpperCase());
