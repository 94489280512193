import { match } from 'ts-pattern';

import { ApiDropFragment } from 'gql/api/api-fragments.generated';
import { ApiDropCollection } from 'gql/api/types-api.generated';
import { ArtworkFragmentExtended } from 'gql/hasura/hasura-fragments.generated';
import { getContractAddress } from 'lib/addresses';
import { collectionSymbolSchema } from 'schemas/collection';

import { CollectionLight, ContractType } from 'types/Collection';
import { Drop, Dynamic } from 'types/Drop';

import { ENV } from './env';
import {
  getFirstValue,
  isAnyTrue,
  isNumberType,
  truncateAddress,
} from './helpers';
import { getPath } from './router';
import { areKeysEqual } from './users';

export type CollectionMicro = {
  contractAddress: ApiDropCollection['contractAddress'];
  slug?: ApiDropCollection['slug'] | null;
};

export const getCollectionLabel = (
  collection: Pick<CollectionLight, 'name' | 'contractAddress'>
) => {
  return collection.name || truncateAddress(collection.contractAddress);
};

export const getCollectionUrlSlug = (collection: CollectionMicro) => {
  return collection?.slug ?? collection?.contractAddress;
};

export const buildCollectionPath = (collection: CollectionMicro) => {
  const id = getCollectionUrlSlug(collection);
  return getPath.collection.page(id);
};

export const buildCollectionPathWithMediaFilter = (
  collection: CollectionMicro,
  distinctAssetKey: string
) => {
  const id = getCollectionUrlSlug(collection);
  return getPath.collection.byMedia(id, distinctAssetKey);
};
export const isFNDContractAddress = (contractAddress: string) =>
  areKeysEqual([
    contractAddress,
    getContractAddress({
      chainId: ENV.PRIMARY_CHAIN_ID,
      contractName: 'nft721',
    }),
  ]);

export const isSharedContract = (contractType: ContractType) => {
  return isAnyTrue([
    contractType === 'FND', // The Foundation shared contract
    contractType === 'SHARED',
  ]);
};

// We use a constant here as its used to match in the custom field due to specific validation behaviour we want
export const SYMBOL_CHARACTER_LIMIT_ERROR =
  'Collection symbols should be less than 8 characters';

export const SYMBOL_SPECIAL_CHARACTER_ERROR =
  "Collection symbols can't contain special characters or spaces";

export const COLLECTION_NAME_MAX_LENGTH_ERROR =
  'Collection names should be less than 48 characters';

export const getMintPriceFromCollectionSale = (
  artwork: ArtworkFragmentExtended
): number | null | Dynamic => {
  if (artwork.collection) {
    const collectionSale = getFirstValue(artwork.collection.collectionSale);
    if (!collectionSale) {
      return null;
    }
    return getMintPrice({
      saleType: collectionSale.saleType,
      mintPrice: collectionSale.mintPrice,
      clearingPrice: collectionSale.clearingPrice,
    });
  } else {
    return null;
  }
};

type MintPriceOptions = {
  /** @deprecated we're migrating to use sale data instead */
  clearingPrice: Drop['clearingPrice'];
  /** @deprecated we're migrating to use sale data instead */
  mintPrice: Drop['mintPrice'];
  /** @deprecated we're migrating to use sale data instead */
  saleType: Drop['saleType'];
};

/**
 * @deprecated because it uses deprecated fields. Try to use getDropSalePrice instead.
 */
export const getMintPrice = (
  options: MintPriceOptions
): number | Dynamic | null => {
  const { saleType, mintPrice, clearingPrice } = options;
  if (
    saleType === 'LINEAR_DUTCH_AUCTION' ||
    saleType === 'STAGGERED_DUTCH_AUCTION'
  ) {
    return isNumberType(clearingPrice) ? clearingPrice : 'dynamic';
  } else {
    return mintPrice;
  }
};

export const getMaxTokenId = (drop: ApiDropFragment) => {
  return match(drop)
    .with(
      {
        saleConfiguration: {
          __typename: 'FixedPriceSaleConfiguration',
        },
      },
      (drop) => drop.saleConfiguration.maxTokenId
    )
    .with(
      {
        saleConfiguration: {
          __typename: 'LinearDutchAuctionSaleConfiguration',
        },
      },
      (drop) => drop.saleConfiguration.maxTokenId
    )
    .otherwise(() => null);
};

export const buildCollectionSymbol = (options: {
  name: string;
  symbol: string | null;
}) => {
  if (options.symbol) {
    return options.symbol.toUpperCase();
  } else {
    return collectionSymbolSchema.parse(options.name);
  }
};
